import { isServiceVisiblAndEntitled } from './scenes/EngagementPlatform/scenes/Session/services/session-utils'

export function prefix(location, ...prefixes) {
  return prefixes.some((p) => location.href.indexOf(`${location.origin}/${p}`) !== -1)
}

/**
 * activityFn: (location) => boolean
 *
 * Must be a pure function. The function is called with window.location as the first argument
 * and should return a truthy value whenever the application should be active.
 */

// eslint-disable-next-line no-unused-vars
export function navbar(location) {
  return true
}

// eslint-disable-next-line no-unused-vars
export function root(location) {
  return true
}

export function vision(location, service) {
  return (
    isServiceVisiblAndEntitled(service) &&
    window.elevate_vision2 &&
    prefix(location, 'engagement/vision2', 'vision2')
  )
}

export function pulse(location, service) {
  return (
    isServiceVisiblAndEntitled(service) &&
    window.elevate_pulse &&
    prefix(location, 'engagement/pulse')
  )
}

export function hackersmind(location, service) {
  return (
    isServiceVisiblAndEntitled(service) &&
    window.elevate_hackersmind &&
    prefix(location, 'engagement/hackersmind')
  )
}

export function reflex(location, service) {
  return (
    isServiceVisiblAndEntitled(service) &&
    window.elevate_reflex &&
    prefix(location, 'engagement/reflex2')
  )
}

function validateServiceByKey(service, key) {
  return Boolean(service && service[key])
}

/**
 * Checks whether this organization or user is allowed to see the service.
 * @param {object} service
 * @returns {Boolean}
 */
export function isServiceVisible(service) {
  return validateServiceByKey(service, 'visibility')
}

/**
 * Checks whether this organization or user is allowed to use the service.
 * @param {object} service
 * @returns {Boolean}
 */
export function isServiceEntitled(service) {
  return validateServiceByKey(service, 'entitlement')
}

/**
 * Checks whether this organization or user is allowed to see and use the service.
 * @param {object} service
 * @returns {Boolean}
 */
export function isServiceVisiblAndEntitled(service) {
  return validateServiceByKey(service, 'visibility') && validateServiceByKey(service, 'entitlement')
}

/**
 * Checks if show_es_roles feature flag is ON and es_role is Admin
 */
export function isAdminRole(es_role, show_es_roles) {
  return !show_es_roles || es_role === 'admin'
}

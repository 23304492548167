import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import defaultMeSelector from './meSelector'

function isLocalDev() {
  return process.env.NODE_ENV === 'development'
}

export function setUserContext(meSelector = defaultMeSelector) {
  return (dispatch, getState) => {
    if (isLocalDev()) return

    const me = meSelector(getState())
    datadogRum.setUser({
      id: me.id,
      name: me.full_name,
      email: me.email
    })
  }
}

const LOG_FUNCTIONS = {
  error: datadogLogs.error,
  warning: datadogLogs.warn,
  info: datadogLogs.info
}

export function sendDatadogError(message = '', level = 'error', extra = {}) {
  if (isLocalDev()) {
    /* eslint-disable no-console */
    console.log(`${level}: ${message}`)
    console.log(extra)
  } else {
    const logFunction = LOG_FUNCTIONS[level.toLowerCase().trim()]
    if (logFunction) {
      let details = extra || {}
      if (extra instanceof Error) {
        details = {
          message: extra.message,
          stack: extra.stack,
          name: extra.name,
          cause: extra.cause
        }
      }

      logFunction(message, details)
    }
  }
}

export function withDatadog(mainCallback) {
  if (isLocalDev()) {
    // local dev
    mainCallback()
  } else {
    const [ORGANIZATION_NID] = window.location.hostname.split('.')
    // Disabling RUM here for now since it appears to be conflicting with
    // ui-vision RUM.  We'll need to follow up to init RUM only here, and pass
    // some data to ui-vision to allow it to configure certain properties and
    // call certain functions. Then we'll have a single RUM project for the
    // entire app.
    // datadogRum.init({
    //   applicationId: 'db2a3d9b-2d26-42f2-9be7-82277e798f51',
    //   clientToken: 'pub7915a2a07413b9fe52da13ed167e07e4',
    //   site: 'datadoghq.com',
    //   service: 'ui-elevateplatform',
    //   env: process.env.REACT_APP_ENV,
    //   version: process.env.REACT_APP_COMMIT_HASH,
    //   sessionSampleRate: 100,
    //   sessionReplaySampleRate: 20,
    //   trackUserInteractions: true,
    //   trackResources: true,
    //   trackLongTasks: true,
    //   defaultPrivacyLevel: 'mask-user-input',
    //   startSessionReplayRecordingManually: true
    // })

    datadogRum.setGlobalContextProperty('organization', ORGANIZATION_NID)

    mainCallback()
  }
}
